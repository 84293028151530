import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Pool } from "../config/Pool";
import { getALLpool } from "../data/pool/getPool";
import { getStakedata } from "../data/stakedata/Getstakedata";
import { StakedData } from "../data/stakedata/fetchStakedData";

interface stakeinfo {
  activestake: StakedData[];
  loading: "idle" | "pending" | "done" | "error";
}

export interface PoolsState {
  loading: "idle" | "pending" | "done" | "error";
  pools: Pool[];
  stakedata: stakeinfo ;
}

const initialState: PoolsState = {
  loading: "idle",
  pools: [],
  stakedata: {
    activestake: [],
    loading: "idle", // Set an initial loading state
  },
};

// Define the slice for pools data and token prices
const Poolslice = createSlice({
  name: "Poolslice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
   builder
      .addCase(getALLpool.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getALLpool.fulfilled, (state, action) => {
        state.loading = "done";
        const { updatedPools } = action.payload;
        state.pools = updatedPools;
      })
      .addCase(getALLpool.rejected, (state, action) => {
        state.loading = "error";
      })
      // builder
      // .addCase(getStakedata.pending, (state) => {
      //   state.stakedata = {
      //     activestake: [],
      //     loading: "pending",
      //   };
      // })
      // .addCase(getStakedata.fulfilled, (state, action) => {
      //   state.stakedata = {
      //     activestake: action.payload.data || [],
      //     loading: "done",
      //   };
      // })
      // .addCase(getStakedata.rejected, (state, action) => {
      //   console.log(action.payload);
      //   state.stakedata = {
      //     activestake: [],
      //     loading: "error",
      //   };
      // });
  },
});

export const {} = Poolslice.actions;
export default Poolslice.reducer;
