import React, { useState, useEffect } from "react";
import {
  getTokeninstance,
  getContractInstanceSigner,
  ConvertEthTonormal,
  getTokeninstanceBysigner,
  FormatUnit,
} from "../../utils/contracthelper";
import { toast } from "react-toastify";

const useStakeTransation = (
  tokenAmount: string,
  stakeToken: string,
  stakecontract: string,
  chainId: number,
  signer: any,
  ABI: any
) => {
  const [TransationLoad, setTransationLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  const Stake = async () => {
    setTransationLoad(true);

    let approvalToastId: any;
    let stakingToastId: any;

    const tokenContractInstance = getTokeninstance(
      stakeToken,
      true,
      chainId,
      signer
    );
    const stakecontractInstance = getContractInstanceSigner(
      stakecontract,
      ABI,
      signer
    );

    // Format the token amount
    const amount = await FormatUnit(tokenAmount, 18);

    try {
      // Check allowance before approval
      approvalToastId = toast.loading("Checking allowance...");
      const currentAllowance = await tokenContractInstance.allowance(
        signer._address,
        stakecontract
      );

      if (currentAllowance >= amount) {
      } else {
        toast.dismiss(approvalToastId);
        approvalToastId = toast.loading("Approval started...");
        const approvalResponse = await tokenContractInstance.approve(
          stakecontract,
          amount
        );
        const approvalReceipt = await approvalResponse.wait();

        if (approvalReceipt) {
          toast.success("Approval successful");
        } else {
        }
      }

      toast.dismiss(approvalToastId);

      // Start staking
      stakingToastId = toast.loading("Staking in progress...");
      const stakeResponse = await stakecontractInstance.stake(amount);
      const stakeReceipt = await stakeResponse.wait();
      toast.dismiss(stakingToastId);
      if (stakeReceipt) {
        toast.success("Staking successful!");
        console.log("Stake transaction completed:", stakeReceipt);
      }
    } catch (error: any) {
      if (error.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user.");
      } else {
        toast.error(
          error.reason || "An error occurred during the staking process."
        );
        console.error("Error during staking:", error);
      }

      // Dismiss any active toasts
      if (approvalToastId) toast.dismiss(approvalToastId);
      if (stakingToastId) toast.dismiss(stakingToastId);
    } finally {
      setTransationLoad(false);
    }
  };

  const endStake = async (stakeId: number) => {
    console.log("hey");
    
    setTransationLoad(true);
    const stakeContractInstance = getContractInstanceSigner(
      stakecontract,
      ABI,
      signer
    );
  
    try {
      const transaction = await stakeContractInstance.withdraw(stakeId);
      const receipt = await transaction.wait();
      toast.success("Claim successful!");
      console.log("Transaction receipt:", receipt);
      setTransationLoad(false);

      return true;
    } catch (error: any) {
      setTransationLoad(false);
      if (error.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user.");
      } else {
        toast.error(
          error.reason || "An error occurred during the staking process."
        );
        console.error("Error during staking:", error);
      }
      return false;
    }
  };
  

  return {
    TransationLoad,
    Stake,
    endStake
  };
};

export default useStakeTransation;
