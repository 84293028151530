import React, { useEffect } from "react";
import { getStakedata } from "../../data/pool/getStake";
import { useAppSelector, useAppdispatch } from "../../hooks/redux";
import { useAccount } from "wagmi";
import Poolbox from "../../components/StakeNew/Poolbox";
import { stakeData } from "../../config/Pool";
type Props = {};

function StakePage({}: Props) {
  const dispatch = useAppdispatch();
  const { address } = useAccount();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getStakedata({ user: address! }));
  }, [address]);

  return (
    <div className="bg-black  min-h-screen py-12  font-ChakraPetch bg-cover bg-center custom-bg bg-fixed">
      
      <div className="absolute right-0 top-0">
        <img className="w-[100vw] h-[100vh]" src="/homegradient.png" />
      </div>
   <div className="px-4">
   <Poolbox stakeData={stakeData} />
   </div>
    </div>
  );
}

export default StakePage;
