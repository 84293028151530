import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./roadmap.css";
import "@rainbow-me/rainbowkit/styles.css";
 import "./extarnal.css"
import "@splidejs/react-splide/css";
import React, { useRef } from "react";
import Coming from "./components/Coming/Coming";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
// v2

import "reactjs-popup/dist/index.css";
import Home from "./pages/Homev2/Home";
import NewStake from "./pages/Staking/index";
// import Bridge from "./pages/Bridge/index";
import store from "./store/store";
import Stakeoverview from "./pages/view/index";
import { ToastContainer, toast } from "react-toastify";
import Layout from "./components/layout/Layout";
import Token from "./pages/Tokencreator/Token";
import Newswap from "./pages/swap/index";
import  Nftmints from "./pages/Nftmints/index";

import NewStakes from "./pages/stake-new/index"

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query'
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import { useEffect } from "react";

import {
  RainbowKitProvider,
  darkTheme,
} from "@rainbow-me/rainbowkit";
import {
  bscTestnet,
  bsc,
  mainnet,
  polygon,
  polygonMumbai,
  arbitrum,
  optimism,
  fantom,
  sepolia,
  baseSepolia
} from "wagmi/chains";
import { WagmiProvider as WagmiConfig  } from 'wagmi';

import { getDefaultConfig } from '@rainbow-me/rainbowkit';
const projectId = process.env.REACT_APP_PROJECT_ID;
export const config:any = getDefaultConfig({
  appName: 'TRT DAPP',
  projectId: projectId!,
  chains: [
    bsc,
  mainnet,
  polygon,
  polygonMumbai,
  arbitrum,
  optimism,
  fantom,
  baseSepolia,
    ...(process.env.REACT_APP_ENABLE_TESTNETS === 'true' ? [sepolia] : []),
  ],
});


i18next
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar", "fr", "ch"],
    fallbackLng: "en",
    debug: false,
    // Options for language detector
    detection: {
      order: ["path", "cookie", "htmlTag"],
      caches: ["cookie"],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
  });

  const queryClient = new QueryClient()

function App() {
  const home = useRef(null);

  useEffect(() => {
    // AOS.init();
  }, []);

  return (
    <WagmiConfig config={config}>
            <QueryClientProvider client={queryClient}>
      <RainbowKitProvider
        // chains={chains}
        initialChain={56}
        theme={darkTheme({
          accentColor: "#D8AA2E",
          accentColorForeground: "white",
          overlayBlur: "small",
          borderRadius: "medium",
        })}
      >
        <Provider store={store}>
    

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            // theme=""
          />

          <Toaster/>
          

       
            <Router>
              <Routes>
                <Route
                  path="/testing"
                  element={
                  <Layout>
                       <Home /> 
                    </Layout>
                  }
                /> 
                <Route
                  path="/comingsoon"
                  element={
                    <Layout>
                      <Coming />
                    </Layout>
                  }
                />
                <Route
                  path="/stake"
                  element={
                    <Layout>
                  <NewStakes />
                    </Layout>
                  }
                />

<Route
                  path="/stake-new"
                  element={
                    <Layout>
                      <NewStakes />
                    </Layout>
                  }
                />


                {/* <Route
                  path="/bridge"
                  element={
                    <Layout>
                      <Bridge />
                    </Layout>
                  }
                /> */}
                <Route
                  path="/view"
                  element={
                    <Layout>
                      <Stakeoverview />
                    </Layout>
                  }
                />
          
                {/* <Route path="/createToken" element={<Create />} /> */}
                <Route
                  path="/createTokenmaker"
                  element={
                    <Layout>
                      <Token />
                    </Layout>
                  }
                />
         
                {/* <Route
                  path="/swap"
                  element={
                    <Layout>
                      <Newswap />
                    </Layout>
                  }
                /> */}
                  <Route
                  path="/nftai"
                  element={
                    <Layout>
                      <Nftmints />
                    </Layout>
                  }
                />
                   
              </Routes>
            </Router>
  
        </Provider>
      </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiConfig>
  );
}

export default App;
