import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { getContractInstance } from "../../utils/contracthelper";
import {
  pools,
  CHAIN_ID_STAKING,
  stakeData,
  CHAIN_ID_STAKING_NEW,
} from "../../config/Pool";

export const getStakedata = createAsyncThunk(
  "getStakedata",
  async (params: { user: string | null }, { rejectWithValue }) => {
    try {
      // Get the contract instance
      const instance = getContractInstance(
        stakeData.contract,
        stakeData.ABI,
        CHAIN_ID_STAKING_NEW
      );

      // Fetch total staked and reward rate (global values)
      const [totalStakeds, rewardRates] = await Promise.all([
        instance.totalSupply(),
        instance.rewardRate(),
      ]);

      let userStakeds = ethers.BigNumber.from(0);
      let totalClaimeds = ethers.BigNumber.from(0);

      // Fetch user-specific data if the user address is provided
      if (params.user) {
        [userStakeds, totalClaimeds] = await Promise.all([
          instance.userTotalStaked(params.user),
          instance.rewards(params.user),
        ]);
      }

      // Normalize values (convert from Wei or BigNumber to readable numbers)
      const normalizedTotalStaked = ethers.utils.formatUnits(
        totalStakeds,
        18 
      );
      const normalizedRewardRate = ethers.utils.formatUnits(rewardRates, 18);
      const normalizedUserStaked = ethers.utils.formatUnits(userStakeds, 18);
      const normalizedTotalClaimed = ethers.utils.formatUnits(
        totalClaimeds,
        18
      );

      return {
        totalStaked: normalizedTotalStaked,
        rewardRate: normalizedRewardRate,
        userStaked: normalizedUserStaked,
        totalClaimed: normalizedTotalClaimed,
      };
    } catch (error: any) {
      console.error("Error fetching stake data:", error);
      return rejectWithValue({
        error: error.message || "Failed to fetch stake data",
      });
    }
  }
);
