import React, { useEffect, useState } from "react";
import LineProgress from "./LineProgress";
import { pools, stakeData, CHAIN_ID_STAKING_NEW } from "../../../config/Pool";
import {
  ConvertEthTonormal,
  pendingReward,
} from "../../../utils/contracthelper";
import { formatDate, calculateDaysInfo } from "../../../utils/date";
import { useAppdispatch } from "../../../hooks/redux";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  formatNumber,
  calculateReward,
  formatAmount,
} from "../../../utils/numbers";
import { ToastContainer, toast } from "react-toastify";
import { useAccount } from "wagmi";
import { StakedData } from "../../../data/stakedata/fetchStakedData";
import useEnd from "../../../hooks/useEnd";
import { getStakedata } from "../../../data/stakedata/Getstakedata";
import { getALLpool } from "../../../data/pool/getPool";
import { useEthersSigner } from "../../../hooks/useEthersSigner";
import useStakeTransation from "../../../hooks/stake/useStake";

type Props = {
  data: StakedData;
  indx: number;
  isbutton?: boolean;
  endLoad?: boolean;
  endStakeFunc?: any;
};

function Stakinfo({ data, indx, isbutton, endLoad, endStakeFunc }: Props) {
  const dispatch = useAppdispatch();
  const { address, chain } = useAccount();
  const { endTime, amount, stakeId, startTime } = data || {};
  const [apy, setAPY] = useState(0);
  const [reward, setReward] = useState(0);
  const signer = useEthersSigner({ chainId: chain?.id });

  const { endStake, TransationLoad } = useStakeTransation(
    "",
    stakeData.tokenAddress,
    stakeData.contract,
    CHAIN_ID_STAKING_NEW,
    signer,
    stakeData.ABI
  );

  const { percentage, daysLeft, daysPassed } = calculateDaysInfo(
    Number(startTime),
    Number(endTime)
  );

  const Amount = Number(amount) / 10 ** stakeData.stakingTokenDecimals;

  useEffect(() => {
    const reloadReward = async () => {
      const rewardAmount = await pendingReward(
        stakeData.contract,
        stakeData.ABI,
        CHAIN_ID_STAKING_NEW,
        address!,
        data.stakeId
      );
      setReward(rewardAmount);
    };
    reloadReward();
  }, [data]);

  const totalAmount = reward / 10 ** stakeData.rewardTokenDecimals + Amount;

  const EndStake = async () => {
    if (daysLeft === "Unlocked") {
      const end = await endStake(stakeId);
      if (end) {
        setTimeout(() => {
          dispatch(getStakedata({ user: address }));
        }, 10000);
      }
    } else {
      toast.warn(`You have to wait ${daysLeft} to end your stake!`);
    }
  };
  

  const Stat = (p: any) => (
    <div className="flex flex-col items-center gap-1.5 w-32 max-[750px]:w-20 max-[600px]:mx-auto ">
      <span className="text-[10px] text-[#fff5]">{p.label}</span>
      <div className="text-[15px] text-center flex gap-0.5 items-center text-white">
        {p.children}
      </div>
    </div>
  );

  return (
    <div
      className={`	border-[1px] border-[#ffffff17] rounded-md py-4 pr-4
      flex justify-between items-center relative overflow-hidden
      gap-y-5 max-[600px]:grid  max-[600px]:grid-cols-2 max-[600px]:px-4 `}
    >
      <div className="absolute inset-0 bg-black -z-20" />
      <div
        className="absolute inset-0 bg-gradient-to-r from-[#fff0] to-[#ffffff17] z-0 "
        style={{ width: 100 + "%" }}
      />

      <Stat hex label="Staked">
        {formatAmount(Amount)} TRT
      </Stat>

      <Stat hex label="Reward">
        {formatAmount(reward / 10 ** stakeData.rewardTokenDecimals)} USDT
      </Stat>

      <Stat label="Unlock">{daysLeft}</Stat>

      <div className="flex items-center flex-col gap-5 justify-center relative z-10">
        <button
          disabled={TransationLoad}
          onClick={() => EndStake()}
          className="text-black hover:opacity-70 transition-all px-3 py-1 rounded-xl flex items-center uppercase bg-zinc-50"
        >
          {!TransationLoad && "end"}  

              <ScaleLoader
                      height={20}
                      loading={TransationLoad}
                      color="#000000"
                      className="text-black"
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
        </button>
      </div>
    </div>
  );
}

export default Stakinfo;
