export function calculateAPY(totalStaked: number, rewardRate: number): string {
  const dailyReward = rewardRate * 86400;
  if (totalStaked <= 0) {
    return "0";
  }

  // Calculate the daily reward rate
  const dailyRewardRate = dailyReward / totalStaked;

  // Calculate simple APY (no compounding)
  const apy = dailyRewardRate * 365; // Annualized based on 365 days

  return `${(apy * 100).toFixed(2)}%`; // Return as a percentage with 2 decimal places
}
