import { GraphQLClient, gql } from "graphql-request";
import { endpoint } from "../../config/Pool";
import { request } from "graphql-request";
export interface StakedData {
  id: string;
  user: string;
  stakeId: number;
  amount: number;
  endTime: number;
  startTime:number;
  active: boolean;
}
export interface Returndata {
  data: StakedData[];
}

export const fetchStakedData = async (user: string): Promise<Returndata> => {
  const query = gql`
    query GetStakedData($user: String!) {
      activestake: stakeds(first: 1000, where: { active: true, user: $user }) {
        id
        user
        stakeId
        amount
        endTime
        active
        startTime
      }

      stakeEnds: stakeds(first: 1000, where: { active: false, user: $user }) {
        id
        user
        stakeId
        startTime
        amount
        endTime
        active
      }
    }
  `;

  // const stakedata = await request<DataResults>(
  //   TokendataClient[id],
  //   STAKE_INFO,
  //   variables
  // );
  const variables = { user };
  const data = await request<{
    activestake: StakedData[];
    stakeEnds: StakedData[];
  }>(endpoint, query, variables);

  console.log(...data.activestake, ...data.stakeEnds,"...data.activestake, ...data.stakeEnds");
  
  return {
    data: [...data.activestake, ...data.stakeEnds],
  };
};
