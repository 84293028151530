export const RPC_CLIENT: { [key: number]: string } = {
    97: 'https://bsc-testnet.publicnode.com',
    80001:"https://rpc-mumbai.maticvigil.com",
    56:"https://bsc-dataseed.binance.org",
    84532:"https://base-sepolia-rpc.publicnode.com",
    42161:"https://arbitrum.llamarpc.com"
};
export const QUOTING_API = "http://localhost:5000/api/global/route"



