import axios from "axios";
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Pool } from "../config/Pool";
import { getALLpool } from "../data/pool/getPool";
import { StakedData } from "../data/stakedata/fetchStakedData";

import { getStakedata } from "../data/pool/getStake";
import { getStakedata as StakeHistory } from "../data/stakedata/Getstakedata";

interface stakeinfo {
  activestake: StakedData[];
  loading: "idle" | "pending" | "done" | "error";
  totalStaked:string,
  rewardRate:string,
  userStaked:string
}

export interface stakeStates {
  loading: "idle" | "pending" | "done" | "error";
  stakedata: stakeinfo ;
}

const initialState: stakeStates = {
  loading: "idle",
  stakedata: {
    activestake: [],
    loading: "idle",
    totalStaked: "0", 
    rewardRate: "0", 
    userStaked: "0",
  },
};

// Define the slice for pools data and token prices
const StakeSlice = createSlice({
  name: "StakeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
   builder
      .addCase(getStakedata.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getStakedata.fulfilled, (state, action) => {
        state.loading = "done";
        state.stakedata.totalStaked = action.payload.totalStaked;
        state.stakedata.rewardRate = action.payload.rewardRate;
        state.stakedata.userStaked = action.payload.userStaked;
      })
      .addCase(getStakedata.rejected, (state, action) => {
        state.loading = "error";
      }),builder
      .addCase(StakeHistory.pending, (state) => {
        state.stakedata.loading = "pending"
      })
      .addCase(StakeHistory.fulfilled, (state, action) => {
          state.stakedata.loading = "done";
          state.stakedata.activestake = action.payload.data || []
      })
      .addCase(StakeHistory.rejected, (state, action) => {
           state.stakedata.loading = 'error'
      });
  },
});

export const {} = StakeSlice.actions;
export default StakeSlice.reducer;
