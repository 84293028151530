// pool abi
import FixedStake01 from "../abi/FixedStake01.json";
import newStake from "../abi/newStake.json";


export const endpoint =
  "https://api.studio.thegraph.com/query/44397/trtstake/version/latest";
export const CHAIN_ID_STAKING = 56;

export const CHAIN_ID_STAKING_NEW = 42161;
export enum PoolType {
  fixedLock,
  Dynamic,
}

export interface subPool {
  index: number;
  apy: number;
  lockDays: number;
}

export interface Pool {
  name: string;
  header: string;
  stakename: string;
  earnname: string;
  rate?: any;
  staketoken: string;
  totaldeposit?: any;
  unclaimed?: string;
  staked?: number;
  poolloading: boolean;
  ABI?: any;
  contract: string;
  stakeFunc: string;
  unstakeFunc: string;
  earnlogosrc?: string;
  poolType: PoolType;
  stakingTokenDecimals: number;
  rewardTokenDecimals: number;
  subpool?: subPool[];
  stakingTokenBalance: string;
  user: string | undefined;
}

//0x9F4617bABb2bcf089F698B802CC6FDeAc1fAF2bF
// old staking - 0xf4d177ba9f41d555f2af2223e0b6ae4c7d5ba305
export const stakeData = {
  stakename: "TRT",
  earnname: "USDT",
  lockDuration: 2231321,
  contract: "0x1430f0b475bDE841Ff1De3CE54021fD28503E921",
  tokenAddress:"0x63db244bC895b3AcCEC6698cE11b0dbD1d3e1C44",
  stakeFunc: "stake",
  unstakeFunc: "EndStake",
  stakingTokenDecimals: 18,
  rewardTokenDecimals: 18,
  ABI:newStake
};

export const pools: Pool[] = [
  {
    name: "SPUNK",
    header: "",
    stakename: "TRT",
    earnname: "TRT",
    earnlogosrc: "spunk",
    rate: "0",
    staketoken: "0x50c7584be73d20760f1947ffcbf7942822c968c8",
    totaldeposit: 0,
    staked: 0,
    unclaimed: "0",
    poolloading: true,
    ABI: FixedStake01,
    contract: "0x80685511b726790049660c2A4E1cF3A1F7ef03d3",
    stakeFunc: "stake",
    unstakeFunc: "EndStake",
    poolType: PoolType.fixedLock,
    stakingTokenDecimals: 18,
    rewardTokenDecimals: 18,
    stakingTokenBalance: "0",
    user: "",
    subpool: [
      {
        index: 0,
        apy: 3,
        lockDays: 30,
      },
      {
        index: 1,
        apy: 10,
        lockDays: 90,
      },
      {
        index: 2,
        apy: 25,
        lockDays: 180,
      },
    ],
  },
];
