import React, { useState, useEffect, useMemo } from "react";
// import Stakinfo from '../Stak'
// import Stakinfo from "./Helper/Stakinfo";
import Stakinfo from "../Activestake/Stakeinfo";
import { StakedData } from "../../../data/stakedata/fetchStakedData";
import Hero from "./Hero";
import { useAccount, useSwitchChain, useBalance } from "wagmi";
import { pools, stakeData, CHAIN_ID_STAKING_NEW } from "../../../config/Pool";
import useStakeTransation from "../../../hooks/stake/useStake";
import { useEthersSigner } from "../../../hooks/useEthersSigner";
type Props = {
  load: "idle" | "pending" | "done" | "error";
  data: StakedData[];
  maxItems?: number;
};

function ActiveStake({ load, data, maxItems = 8 }: Props) {
  const { address, chain } = useAccount();
  const signer = useEthersSigner({ chainId: chain?.id });

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [sortingOption, setSortingOption] = useState<"active" | "inactive">(
    "active"
  );

  useEffect(() => {
    let extraPages = 1;
    if (data?.length % maxItems === 0) {
      extraPages = 0;
    }
    setMaxPage(Math.floor(data?.length / maxItems) + extraPages);
  }, [maxItems, data]);

  const sorteddata = useMemo(() => {
    return data ? data?.slice(maxItems * (page - 1), page * maxItems) : [];
  }, [data, maxItems, page]);

  const activeStakes = useMemo(() => {
    return sorteddata ? sorteddata?.filter((item) => item.active === true) : [];
  }, [data]);

  const inactiveStakes = useMemo(() => {
    return sorteddata
      ? sorteddata?.filter((item) => item.active === false)
      : [];
  }, [data]);

  const displayedStakes =
    sortingOption === "active" ? activeStakes : inactiveStakes;

  return (
    <div className="w-[1032px] max-w-[90%] mx-auto overflow-x-hidden border-x border-[#111] max-[800px]:border-none        pb-8 px-4  max-[800px]:pt-[20px] max-[800px]:px-0 max-[800px]:pb-24        min-h-[calc(100vh-94px)] max-[800px]:min-h-[calc(100%-66px-80px)]        ">
      <h1 className="text-center text-3xl font-extrabold">Active Stakes</h1>

      <div className="flex flex-col items-start px-20 max-[1000px]:px-8 max-[800px]:px-0">
        <Hero data={data} />
        <div>
          <div
            className="
		flex flex-wrap justify-center border-2 gap-2 border-[#ffffff17] p-0.5 bg-black rounded-md"
          >
            <button
              onClick={() => setSortingOption("active")}
              className={`${
                sortingOption === "active" ? " bg-[#ffffff]" : ""
              } pt-px text-sm font-semibold	hover:bg-[#fff1] cursor-pointer bg-[#fff2] h-[32px] px-2.5 rounded-[3px] flex items-center gap-1`}
            >
              {activeStakes.length} Active
            </button>
            <button
              onClick={() => {
                setSortingOption("inactive");
              }}
              className={`${
                sortingOption === "inactive" ? " bg-[#ffffff]" : ""
              } pt-px text-sm font-semibold	hover:bg-[#fff1] cursor-pointer   bg-[#fff2] h-[32px] px-2.5 rounded-[3px] flex items-center gap-1`}
            >
              {inactiveStakes?.length} Past
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 w-full gap-y-6 mt-5 max-w-[1150px] gap-x-5">
          {load === "done" ? (
            displayedStakes.length > 0 ? (
              displayedStakes.map((e: StakedData, indx) => (
                <Stakinfo key={e.id} data={e} indx={indx} />
              ))
            ) : (
              <p className="text-white text-center">No stake found.</p>
            )
          ) : load === "idle" ? (
            "--"
          ) : (
            <div className="text-white text-center">loading...</div>
          )}
        </div>
      </div>

      {/* <div className="px-2 py-4 text-white mx-auto flex flex-row gap-3 justify-center dark:text-darktext mt-5">
        <button
          onClick={() => {
            setPage(page === 1 ? page : page - 1);
          }}
        >
          ←
        </button>
        {"Page " + page + " of " + maxPage}

        <button
          onClick={() => {
            setPage(page === maxPage ? page : page + 1);
          }}
        >
          →
        </button>
      </div> */}
    </div>
  );
}

export default ActiveStake;
