import React, { useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { StakedData } from "../../../data/stakedata/fetchStakedData";
import { pools, CHAIN_ID_STAKING_NEW } from "../../../config/Pool";
import { useAppSelector, useAppdispatch } from "../../../hooks/redux";
import { getALLpool } from "../../../data/pool/getPool";
import { useAccount, useBalance } from "wagmi";
import {
  formatNumber,
  calculateReward,
  formatAmount,
} from "../../../utils/numbers";
import Skeleton from "../pool/Skeleton";
import { stakeData } from "../../../config/Pool";
type Props = {
  data: StakedData[];
};

function Hero({ data }: Props) {
  const { address } = useAccount();

  const {
    data: StakeTokenBalance,
    isLoading: balanceLoading,
    refetch: refetchBalance,
  } = useBalance({
    address: address,
    token: stakeData.tokenAddress as "0x",
    chainId: CHAIN_ID_STAKING_NEW,
  });

  useEffect(() => {
    refetchBalance();
  }, [address]);

  const activeStakesAmount = useMemo(() => {
    if (!data) {
      return 0;
    }

    const totalAmount = data
      .filter((item) => item.active === false)
      .reduce((sum, item) => sum + Number(item.amount), 0);
    return formatNumber(totalAmount / 10 ** stakeData.stakingTokenDecimals);
  }, [data]);

  return (
    <div className="   h-[300px] text-white -mt-16 flex items-center justify-between w-full   max-[500px]:-mt-8 max-[500px]:h-[200px] relative  ">
      <div className="flex flex-col items-start">
        {StakeTokenBalance ? (
          <div className="font-bold flex items-end gap-1 text-wrap">
            <div className="text-wrap text-2xl md:text-4xl custom-wrap  ">
              {StakeTokenBalance?.formatted}
            </div>
            <div>
              <span className="text-sm">TRT</span>
            </div>
          </div>
        ) : (
          <div className="mb-2">
            {address ? (
              <Skeleton />
            ) : (
              <span className="text-wrap text-2xl md:text-4xl custom-wrap ">
                0
              </span>
            )}
          </div>
        )}
        <div className="text-[12px] text-[#fff7]">
          {activeStakesAmount} TRT staked
        </div>
      </div>
      <div className="flex gap-4 max-[500px]:absolute max-[500px]:right-0 max-[500px]:top-0">
        {/* <button className="btn bigBtn secondary w-[54px]"></button> */}

        <Link
          to="/stake"
          className="transition-all flex items-center gap-1 px-4  hover:opacity-70 font-semibold  sm:h-[44px] w-auto h-auto sm:py-0 py-2 bg-gradient-to-r bg-white text-black  rounded-[5px]  sm:flex-initial flex-1 justify-center   disabled:bg-slate-500 disabled:cursor-not-allowed"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-plus "
          >
            <path d="M5 12h14"></path>
            <path d="M12 5v14"></path>
          </svg>
          Stake
        </Link>
      </div>
    </div>
  );
}

export default Hero;
