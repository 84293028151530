import React, { useEffect, useState } from "react";
import { pools, stakeData } from "../../config/Pool";
import { useAppSelector, useAppdispatch } from "../../hooks/redux";
import { useAccount, useSwitchChain, useBalance } from "wagmi";
import { CHAIN_ID_STAKING_NEW } from "../../config/Pool";
import { useMediaQuery } from "react-responsive";
import { InfoLine } from "./InfoLine";
import { formatNumber } from "../../utils/numbers";
import AmountInput from "./AmountInput";
import ScaleLoader from "react-spinners/ScaleLoader";
import { useEthersSigner } from "../../hooks/useEthersSigner";
import { ToastContainer, toast } from "react-toastify";
import Connetbtn from "../Header/Connetbtn";
import { Link } from "react-router-dom";
import useStakeTransation from "../../hooks/stake/useStake";
import { calculateAPY } from "../../utils/stake";
import { getStakedata } from "../../data/pool/getStake";
type Props = {
  stakeData: any;
};

function Poolbox({ stakeData }: Props) {
  const dispatch = useAppdispatch();
  const { address, chain } = useAccount();
  const signer = useEthersSigner({ chainId: chain?.id });

  const { loading, stakedata } =
    useAppSelector((state) => state.stakeSlice) || {};
  const isWRONG_NETWORK = CHAIN_ID_STAKING_NEW != chain?.id;

  const [tokenamount, settokenamount] = useState("");
  const [apy, setApy] = useState(0);

  const { data: evmbalance } = useBalance({
    address,
    chainId: chain?.id,
    token: stakeData.tokenAddress ?? "0x",
    query: {
      refetchInterval: 5000,
    },
  });

  useEffect(() => {
    const apy = calculateAPY(
      Number(stakedata.totalStaked),
      Number(stakedata.rewardRate)
    );
  }, [stakedata]);

  const { chains, error, switchChainAsync, isPending } = useSwitchChain();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 550px)" });
  const chainId = chain?.id || CHAIN_ID_STAKING_NEW;
  const { TransationLoad, Stake } = useStakeTransation(
    tokenamount,
    stakeData.tokenAddress,
    stakeData.contract,
    chainId,
    signer,
    stakeData.ABI
  );

  const ChangeChain = () => {
    switchChainAsync({ chainId: CHAIN_ID_STAKING_NEW });
  };

  const handleaddamount = async (e: string) => {
    settokenamount(e);
  };

  const handleStake = async () => {
    await Stake();
    dispatch(getStakedata({ user: address! }));

  };

  return (
    <div className="m-3  p-[.5px]  whitespace-nowrap bg_btn_gr w-fit mx-auto    rounded-2xl relative  ">
      <div className="h-[7px]"></div>
      <div className=" m-[1px]  bg-black   whitespace-nowrap rounded-2xl    sm:w-[500px] h-auto    flex flex-col">
        {/* title */}
        <div className="px-4 md:px-7 py-4 w-full bg-gradient-to-r from-[#fff0] to-[#ffffff17] border-[#ffffff17] border-b  rounded-2xl ">
          <h1 className="text-lg tracking-wide text-white md:text-2xl font-extrabold flex flex-row items-end py-1 gap-5">
            Stake TRT
          </h1>
          <p className="flex flex-row  gap-3 text-gray-400">Earn USDT</p>
        </div>

        {/* title */}

        {/* 1st selction */}
        <div className="p-5 md:p-7 flex flex-col gap-y-3">
          {/* total deposit  and user deposit  */}
          <InfoLine
            text={`${isTabletOrMobile ? "Total Staked" : "Total Staked"}`}
            load={loading == "done"}
            value={
              stakedata.totalStaked
                ? formatNumber(Number(stakedata.totalStaked))
                : 0
            }
          />

          <InfoLine
            text={`${
              isTabletOrMobile ? "My Staked Balance" : "My Staked Balance"
            }`}
            load={loading == "done"}
            value={
              stakedata.userStaked
                ? formatNumber(Number(stakedata.userStaked))
                : 0
            }
          />

          <InfoLine
            text={"Lock Deadline"}
            load={loading == "done"}
            value={"90 Days"}
          />

          <InfoLine
            text={"Daily Reward"}
            load={loading == "done"}
            value={"Dynamic"}
          />

          {/* <InfoLine
            text={`APY Rate            `}
            load={loading == "done" && !poolloading}
            value={`${subpool ? subpool[activeSubpoolIndex].apy : 0}%`}
          /> */}

          <AmountInput
            handlevaluechange={handleaddamount}
            value={tokenamount}
            label="Amount"
            maxValue={Number(evmbalance?.formatted).toFixed(0)}
          />
          {address && !isWRONG_NETWORK  &&  (
            <div className="text-sm text-right text-white text-wrap">
              Available Balance:{" "}
              {formatNumber(Number(Number(evmbalance?.formatted).toFixed(3)))}{" "}
              TRT
            </div>
          )}

          {/* unclaimed reward */}

          {/* unclaimed reward */}

          <div className="flex flex-col justify-center gap-5 pt-3">
            {!address && <Connetbtn />}
            {/* claim button */}

            {/* claim button */}
            {address && (
              <div>
                {!isWRONG_NETWORK ? (
                  <button
                    disabled={TransationLoad}
                    onClick={() => handleStake()}
                    className={`stake_btn w-full`}
                  >
                    {!TransationLoad && "STAKE"}
                    <ScaleLoader
                      height={20}
                      loading={TransationLoad}
                      color="#ffffff"
                      className="text-white"
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  </button>
                ) : (
                  <div className="">
                    <button
                      onClick={() => ChangeChain()}
                      disabled={isPending}
                      className={`stake_btn w-full `}
                    >
                      Wrong Network!
                    </button>
                    <p className="text-center py-2 text-red-700 text-wrap font-bold">
                      Please switch to the correct network to access this pool.
                    </p>
                  </div>
                )}
              </div>
            )}
            {address && (
              <Link
                to={`/view`}
                // onClick={() => unstake()}
                // disabled={!IsUnstaked}
                className={`stake_btn w-full`}
              >
                UNSTAKE
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Poolbox;
