import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { fetchStakedData } from "./fetchStakedData";

export const getStakedata = createAsyncThunk(
  "getStakingHistory",
  async (parms: { user: string | any }, { dispatch,rejectWithValue }) => {
    try {
      const {data} = await fetchStakedData(parms.user);
      return {
        data
      };
    } catch (e:any) {
      console.log(e);

      return {
        activestake:[],
        stakeEnds:[]
      };
    }
  }
);
